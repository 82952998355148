(function ($) {
  $(window).load(function () {

    // owl-carousel
    $('.owl-carousel:not(.slider):not(.mobile-owl-carousel)').owlCarousel({
      items: 1,
      loop: false,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: false,
      dots: false,

      // drag
      mouseDrag: false,

      onInitialized: function(e) {
        $('ul.quick-links li:nth-child('+(e.item.index+1)+')').addClass('active');
      }

    }).on('changed.owl.carousel', function(e) {
      $(this)
        .find('.owl-item')
        .removeClass('hover-active');

      $('ul.quick-links li').removeClass('active');

      if(hoveringLi) {
        $(this).find('.owl-item:nth-child('+(e.item.index+1)+')').addClass('hover-active');
      } else {
        $('ul.quick-links li:nth-child('+(e.item.index+1)+')').addClass('active');
      }
    });

    var hoveringLi = false;

    $('ul.quick-links li').mouseenter( function() {
      var child = $(this).get(0);
      var parent = $(this).parent().get(0);
      var index = Array.prototype.indexOf.call(parent.children, child);
      //index++;
      console.log(index);
      hoveringLi = true;
      $('.owl-carousel:not(.slider):not(.mobile-owl-carousel)').trigger('to.owl.carousel', [index, 500, true]).trigger('stop.owl.autoplay');

    } ).mouseleave( function() {
      hoveringLi = false;
      $('.owl-carousel:not(.slider):not(.mobile-owl-carousel)').trigger('play.owl.autoplay');

    } );


    // mobile-owl-carousel
    $('.mobile-owl-carousel').owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: false,
      dots: true,

      // drag
      mouseDrag: false
    })

    // slider
    $('.slider:not(.program-slider)').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });


    // program-slider
    $('.program-slider').owlCarousel({
      items: 1,
      margin: 0,
      loop: false,

      // autoplay
      autoplay: false,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: false,

      // drag
      mouseDrag: false,

      // responsive
      responsive: {
        0: {
          items: 1,
          mouseDrag: true
        },
        576: {
          items: 1,
          mouseDrag: true
        },
        768: {
          items: 2
        },
        992: {
          items: 2
        },
        1200: {
          items: 2
        }
      }
    });

    // form-sent
    setTimeout(() => {

      var locationSearch = '' + document.location.search;
      if (
        (-1 === locationSearch.indexOf('form[sent]') && -1 === decodeURIComponent(locationSearch).indexOf('form[sent]'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $headerHeight = $('.header');
      var $message = $('.form-success-message');

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $('html, body').animate({ scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30 });
    }, 500);

  });

  $(document).ready(function () {
    // sticky
    $(window).scroll(function () {
      var windowWidth = $(window).width();
      var $el = $('body').add('.header');

      if ($(window).scrollTop() > 0) {
        $el.addClass('sticky');
      } else {
        $el.removeClass('sticky');
      }
    });

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);
